import { createSlice } from '@reduxjs/toolkit';
import {getTrade, getTrades, createTrade, updateTrade, removeTrade, createTradeComment} from "./tradesClient";
import {fetchCards} from "../cards/cardsSlice";
import {fetchUser} from "../users/userSlice";
import {addMessage} from "../alerts/messagesSlice";
import {v1 as uuidv1} from "uuid";

function startLoading(state) {
    state.isLoading = true;
}

function loadingFailed(state, action) {
    state.isLoading = false;
    state.error = action.payload;
}
function basicUpdate(state, {payload}) {
    const {trade, updated} = payload;
    state.tradesById[trade.id] = trade;
    state.tradesById[trade.id].updated = updated;
    state.isLoading = false;
    state.error = null;
}

// createSlice and createReducer wrap your function with produce from the Immer library.
// This means you can write code that "mutates" the state inside the reducer, and Immer will safely return a correct immutably updated result.
const tradesSlice = createSlice({
    name: `trades`,
    initialState: {
        tradesById: {},
        refreshInterval: 300000, /* in microseconds */
        isLoading: false,
        updated: ``,
        error: null,
    },
    reducers: {
        getTradesStart: startLoading,
        getTradeStart: startLoading,
        newTradeStart: startLoading,
        deleteTradeStart: startLoading,
        acceptTradeStart: startLoading,
        shipTradeStart: startLoading,
        cancelTradeStart: startLoading,
        completeTradeStart: startLoading,
        disputeTradeStart: startLoading,
        getTradesSuccess(state, {payload}) {
            const {trades, updated} = payload;
            trades.forEach(trade => {
                state.tradesById[trade.id] = trade;
            });
            state.updated = updated;
            state.isLoading = false;
            state.error = null;
        },
        getTradeSuccess: basicUpdate,
        newTradeSuccess: basicUpdate,
        deleteTradeSuccess(state, {payload}) {
            const {tradeId} = payload;
            delete state.tradesById[tradeId];
            state.isLoading = false;
            state.error = null;
        },
        acceptTradeSuccess: basicUpdate,
        shipTradeSuccess: basicUpdate,
        cancelTradeSuccess: basicUpdate,
        completeTradeSuccess: basicUpdate,
        disputeTradeSuccess: basicUpdate,
        getTradesFailure: loadingFailed,
        getTradeFailure: loadingFailed,
        newTradeFailure: loadingFailed,
        deleteTradeFailure: loadingFailed,
        acceptTradeFailure: loadingFailed,
        shipTradeFailure: loadingFailed,
        cancelTradeFailure: loadingFailed,
        completeTradeFailure: loadingFailed,
        disputeTradeFailure: loadingFailed,
    }
});

export const {
    getTradesStart,
    getTradesSuccess,
    getTradesFailure,
    getTradeStart,
    getTradeSuccess,
    getTradeFailure,
    newTradeStart,
    newTradeSuccess,
    newTradeFailure,
    deleteTradeStart,
    deleteTradeSuccess,
    deleteTradeFailure,
    acceptTradeStart,
    acceptTradeSuccess,
    acceptTradeFailure,
    shipTradeStart,
    shipTradeSuccess,
    shipTradeFailure,
    cancelTradeStart,
    cancelTradeSuccess,
    cancelTradeFailure,
    completeTradeStart,
    completeTradeSuccess,
    completeTradeFailure,
    disputeTradeStart,
    disputeTradeSuccess,
    disputeTradeFailure,
} = tradesSlice.actions;

export default tradesSlice.reducer;

// Thunks
export const fetchTrades = (filter = `active`) => async dispatch => {
    try {
        dispatch(getTradesStart());
        const trades = await getTrades(filter);
        // Tell the Card slice to load the card details we need
        const cardIds = [];
        trades.forEach(trade => {
            dispatch(fetchUser(trade.user_id));
            dispatch(fetchUser(trade.partner_id));
            trade.items && trade.items.forEach(item => {
                !cardIds.includes(Number(item.card_id)) && cardIds.push(Number(item.card_id));
            });
        });
        dispatch(fetchCards(cardIds));
        dispatch(getTradesSuccess({trades, updated: Date.now()}));
    } catch (err) {
        dispatch(getTradesFailure(err.toString()));
    }
};
export const fetchTrade = (tradeId) => async dispatch => {
    try {
        dispatch(getTradeStart());
        const trade = await getTrade(tradeId);
        if (trade.length === 0) {
            dispatch(getTradeFailure(`Trade not found or inaccessible`));
            return;
        }
        dispatch(fetchUser(trade.user_id));
        dispatch(fetchUser(trade.partner_id));
        dispatch(getTradeSuccess({trade, updated: Date.now()}));
    } catch (err) {
        dispatch(getTradeFailure(err.toString()));
    }
};
export const newTrade = (tradeData, tradeItemData, tradeComment) => async dispatch => {
    dispatch(newTradeStart());
    try {
        const trade = await createTrade(tradeData, tradeItemData);
        if (trade.error) {
            dispatch(newTradeFailure(trade.error.toString()));
            dispatch(addMessage({id:uuidv1(), text:trade.error.toString(), priority: `low`}));
            return;
        }
        tradeComment && await createTradeComment(trade.id, tradeComment);
        const message = trade.message ? trade.message.toString() : `New trade created`;
        dispatch(addMessage({id: uuidv1(), text: message, priority: `low`}));
        dispatch(newTradeSuccess({trade, updated: Date.now()}));
    } catch (err) {
        dispatch(newTradeFailure(err.toString()));
    }
};
export const deleteTrade = (tradeId) => async dispatch => {
    try {
        dispatch(deleteTradeStart());
        const trade = await removeTrade(tradeId);
        if (trade.error) {
            dispatch(deleteTradeFailure(trade.error.toString()));
            dispatch(addMessage({id:uuidv1(), text:trade.error.toString(), priority: `low`}));
            return;
        }
        const message = trade.message ? trade.message.toString() : `Trade cancelled`;
        dispatch(addMessage({id: uuidv1(), text: message, priority: `low`}));
        dispatch(deleteTradeSuccess({tradeId, updated: Date.now()}));
    } catch (err) {
        dispatch(deleteTradeFailure(err.toString()));
    }
};
export const acceptTrade = (tradeId) => async dispatch => {
    try {
        dispatch(acceptTradeStart());
        const trade = await updateTrade(tradeId, {action:`accept`});
        if (trade.error) {
            dispatch(acceptTradeFailure(trade.error.toString()));
            return;
        }
        dispatch(acceptTradeSuccess({trade, updated: Date.now()}));
    } catch (err) {
        dispatch(acceptTradeFailure(err.toString()));
    }
};
export const cancelTrade = (tradeId) => async dispatch => {
    try {
        dispatch(cancelTradeStart());
        const trade = await removeTrade(tradeId);
        if (trade.error) {
            dispatch(cancelTradeFailure(trade.error.toString()));
            dispatch(addMessage({id:uuidv1(), text:trade.error.toString(), priority: `low`}));
            return;
        }
        const message = trade.message ? trade.message.toString() : `Trade cancel requested`;
        dispatch(addMessage({id: uuidv1(), text: message, priority: `low`}));
        dispatch(cancelTradeSuccess({trade, updated: Date.now()}));
    } catch (err) {
        dispatch(cancelTradeFailure(err.toString()));
    }
};
export const shipTrade = (tradeId) => async dispatch => {
    try {
        dispatch(shipTradeStart());
        const trade = await updateTrade(tradeId, {action:`ship`});
        if (trade.error) {
            dispatch(shipTradeFailure(trade.error.toString()));
            return;
        }
        dispatch(shipTradeSuccess({trade, updated: Date.now()}));
    } catch (err) {
        dispatch(shipTradeFailure(err.toString()));
    }
};
export const completeTrade = (tradeId) => async dispatch => {
    try {
        dispatch(completeTradeStart());
        const trade = await updateTrade(tradeId, {action:`complete`});
        if (trade.error) {
            dispatch(completeTradeFailure(trade.error.toString()));
            return;
        }
        dispatch(completeTradeSuccess({trade, updated: Date.now()}));
    } catch (err) {
        dispatch(completeTradeFailure(err.toString()));
    }
};
export const disputeTrade = (tradeId) => async dispatch => {
    try {
        dispatch(disputeTradeStart());
        const trade = await updateTrade(tradeId, {action:`dispute`});
        if (trade.error) {
            dispatch(disputeTradeFailure(trade.error.toString()));
            return;
        }
        dispatch(disputeTradeSuccess({trade, updated: Date.now()}));
    } catch (err) {
        dispatch(disputeTradeFailure(err.toString()));
    }
};
