import PropTypes from "prop-types";
import {useQuery} from "react-query";
import {getIntegrationToken} from '@commercelayer/js-auth';
import {InventoryModel, Market, Merchant, PaymentGateway, PaymentMethod, Price, ShippingMethod, Sku, StockItem} from '@commercelayer/js-sdk'
import {createSkuFilter} from "../features/products/utils";

const endpoint = `https://ccg-trader.commercelayer.io`;
// Public integration using "Everyone" role
//const testingClientId = `urQOzo9G0SH7SVzVSSXRLyczUQLfBAO2NcnNQcM9fK0`;
//const testingClientSecret = `QyKGX7vqJVt_BSBLp9MR3bftEsE_8M5kcZl6-MB8TTc`;
const clientId = `uA6Qorx25winuf4IrTvp8vHBLljK6QKC_kcX42V9Ouw`;
const clientSecret = `LDe5fnmTU3WS4uIwXkxljXNpGw77itRMt7is88bqKJE`;
const scope = `market:all`;

const convertFilters = ({limit = 12, page = 1, ...filters}) => {
    let apiFilters = {...filters.Api};
    // User interface filters to convert to CommerceLayer JS SDK syntax
    if (filters.u) {
        apiFilters.referenceEq = filters.u
    }
    if (filters.g) {
        apiFilters = {...apiFilters, ...createSkuFilter({gameRef:filters.g})};
    }

    return {apiFilters, limit, page}
}

const useCommerceLayerAuth = () => {
    return useQuery([`token`, clientId], () => getIntegrationToken({
        clientId: clientId,
        clientSecret: clientSecret,
        endpoint: endpoint,
        scope: scope,
    }));
};

const useInventoryModels = ({ filters, enabled = true }) => {
    const {apiFilters, limit, page} = convertFilters(filters);
    return useQuery({
        queryKey: [`inventory_models`, filters],
        queryFn: () => getInventoryModels({apiFilters, limit, page}),
        select: data => ({
            data: Array.from(data.data),
            meta: {
                ...data.meta,
                filter_count: data.meta.record_count,
                page: page,
            }
        }),
        enabled: enabled,
    });
};

const useMarkets = ({ filters, enabled = true }) => {
    const {apiFilters, limit, page} = convertFilters(filters);
    return useQuery({
        queryKey: [`markets`, filters],
        queryFn: () => getMarkets({apiFilters, limit, page}),
        select: data => ({
            data: Array.from(data.data),
            meta: {
                ...data.meta,
                filter_count: data.meta.record_count,
                page: page,
            }
        }),
        enabled: enabled,
    });
};

const useMerchants = ({ filters, enabled = true }) => {
    const {apiFilters, limit, page} = convertFilters(filters);
    return useQuery({
        queryKey: [`merchants`, filters],
        queryFn: () => getMerchants({apiFilters, limit, page}),
        select: data => ({
            data: Array.from(data.data),
            meta: {
                ...data.meta,
                filter_count: data.meta.record_count,
                page: page,
            }
        }),
        enabled: enabled,
    });
};

const usePaymentGateways = ({ filters, enabled = true }) => {
    const {apiFilters, limit, page} = convertFilters(filters);
    return useQuery({
        queryKey: [`payment_gateways`, filters],
        queryFn: () => getPaymentGateways({apiFilters, limit, page}),
        enabled: enabled,
    });
};

const usePaymentMethods = ({ filters, enabled = true }) => {
    const {apiFilters, limit, page} = convertFilters(filters);
    return useQuery({
        queryKey: [`payment_methods`, filters],
        queryFn: () => getPaymentMethods({apiFilters, limit, page}),
        enabled: enabled,
    });
};

const usePrices = ({ filters, enabled = true }) => {
    return useQuery({
        queryKey: [`price`, filters],
        queryFn: () => Price.select(`currency_code`,`amount_cents`,`formatted_amount`).findBy({ sku_code: filters.Api.sku_code, reference: filters.Api.reference }),
        enabled: enabled,
    });
};

const usePriceId = ({ filters, enabled = true }) => {
    return useQuery({
        queryKey: [`price`, filters],
        queryFn: () => Price.select(`id`).findBy({ sku_code: filters.Api.sku_code, reference: filters.Api.reference }),
        enabled: enabled,
    });
};

const useProducts = ({ filters, enabled = true }) => {
    const {apiFilters, limit, page} = convertFilters(filters);
    return useQuery({
        queryKey: [`products`, filters],
        queryFn: () => getProducts({apiFilters, limit, page}),
        select: data => ({
            data: Array.from(data.data),
            meta: {
                ...data.meta,
                filter_count: data.meta.record_count
            }
        }),
        enabled: enabled,
    });
};

const useShippingMethods = ({ filters, enabled = true }) => {
    const {apiFilters, limit, page} = convertFilters(filters);
    return useQuery({
        queryKey: [`shipping`, filters],
        queryFn: () => getShippingMethods({apiFilters, limit, page}),
        enabled: enabled,
    });
};

const useSkus = ({ skuCode }) => {
    return useQuery({
        queryKey: [`skus`, skuCode],
        queryFn: () => Sku.select(`id`,`code`,`name`).findBy({ code: skuCode }),
        enabled: !!skuCode
    });
};

const useStockItems = ({ filters }) => {
    const {apiFilters, limit, page} = convertFilters(filters);
    return useQuery({
        queryKey: [`stock`, filters],
        queryFn: () => getStockItems({apiFilters, limit, page}),
        select: data => ({
            data: Array.from(data.data),
            meta: {
                ...data.meta,
                filter_count: data.meta.record_count
            }
        }),
        enabled: !!filters
    });
};

const getInventoryModels = ({apiFilters, limit, page}) => {
    return InventoryModel.select().where(apiFilters).perPage(limit).page(page).all();
}

const getMarkets = ({apiFilters, limit, page}) => {
    return Market.select().where(apiFilters).perPage(limit).page(page).all();
}

const getMerchants = ({apiFilters, limit, page}) => {
    return Merchant.select().where(apiFilters).perPage(limit).page(page).all();
}

const getPaymentGateways = ({apiFilters, limit, page}) => {
    return PaymentGateway.select().where(apiFilters).perPage(limit).page(page).all();
}

const getPaymentMethods = ({apiFilters, limit, page}) => {
    return PaymentMethod.select().where(apiFilters).perPage(limit).page(page).all();
}

const getProducts = ({apiFilters, limit, page}) => {
    return StockItem.select(`quantity`, `sku_code`, `reference`).where({quantityGt: 0, stockLocationNameNotEnd: `[HIDDEN]`, ...apiFilters}).perPage(limit).page(page).all();
}

const getShippingMethods = ({apiFilters, limit, page}) => {
    return ShippingMethod.includes(`shipping_zone`).select(`name`, `price_amount_cents`, `formatted_price_amount`, `shipping_zone`, {shipping_zones:[`country_code_regex`]}).where(apiFilters).perPage(limit).page(page).all();
}

const getStockItems = ({apiFilters, limit, page}) => {
    return StockItem.select(`quantity`, `sku_code`, `reference`).where({ quantityGt: 0, stockLocationNameNotEnd: `[HIDDEN]`, ...apiFilters }).perPage(limit).page(page).all();
}

useProducts.propTypes = {
    filters: PropTypes.object.isRequired,
};
getProducts.propTypes = {
    filters: PropTypes.object.isRequired,
    limit: PropTypes.number,
    page: PropTypes.number,
};

export {endpoint, useCommerceLayerAuth, useInventoryModels, useMarkets, useMerchants, usePaymentGateways, usePaymentMethods, usePriceId, usePrices, useShippingMethods, useSkus, useStockItems}
