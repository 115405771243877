import * as React from 'react';
import { globalHistory, Location } from '@reach/router';
import { QueryParamProvider } from 'use-query-params';
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux';
import {ThemeProvider} from "@material-ui/core/styles";
import {CssBaseline} from "@material-ui/core";
import {UIProvider} from "./uiContext";
import store from './store';
import {AppProviders} from "../context";
import darkTheme from "../themes/defaultDark";

const queryClient = new QueryClient();

// eslint-disable-next-line react/display-name,react/prop-types
export const WrapWithProviders = ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    return (
        <UIProvider>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={darkTheme}>
                    <CssBaseline />
                    <Provider store={store}>
                        <AppProviders>
                            <Location>
                                {({ location }) => (
                                    <QueryParamProvider location={location} reachHistory={globalHistory}>
                                        {element}
                                    </QueryParamProvider>
                                )}
                            </Location>
                        </AppProviders>
                    </Provider>
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </UIProvider>
    )
}
