import { combineReducers } from 'redux';
import gamesReducer from '../features/games/gamesSlice';
import cardsReducer from '../features/cards/cardsSlice';
import listsReducer from '../features/lists/listSlice';
import messagesReducer from '../features/alerts/messagesSlice';
import tradesReducer from '../features/trades/tradesSlice';
import usersReducer from '../features/users/userSlice';
import {createAction} from "@reduxjs/toolkit";

export const resetApp = createAction(`resetApp`);

const appReducer = combineReducers({
    games: gamesReducer,
    cards: cardsReducer,
    lists: listsReducer,
    messages: messagesReducer,
    trades: tradesReducer,
    users: usersReducer,
});
const rootReducer = (state, action) => {
   // Clear all data in redux store to initial.
   if(action.type === `resetApp`) {
       state = undefined;
   }
   return appReducer(state, action);
};
export default rootReducer;
