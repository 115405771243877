import * as React from "react";
import {initCLayer} from "@commercelayer/js-sdk";

const initial = {
    accessToken: ``,
    endpoint: ``,
    cache: false,
}

const CommerceLayerCtx = React.createContext(initial);

const CommerceLayerProvider = ({ accessToken, children, cache = false, endpoint }) => {
    initCLayer({accessToken, endpoint, options: {rawResponse: true}});
    return (
        <CommerceLayerCtx.Provider value={{ accessToken, cache, endpoint }}>
            {children}
        </CommerceLayerCtx.Provider>
    )
}

const useCommerceLayer = () => {
    const context = React.useContext(CommerceLayerCtx);
    if (context === undefined) {
        throw new Error(
            `useCommerceLayer must be used within a CommerceLayerProvider`,
        )
    }
    return context;
}

export {CommerceLayerProvider, useCommerceLayer}
