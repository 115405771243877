import { createTheme } from "@material-ui/core/styles";
import * as commonStyling from "../commonStyling"

const afterOpacity = (o,fg=[255,255,255],bg=[18,18,18]) => fg.map((colFg,idx)=>o*colFg+(1-o)*bg[idx]);

const darkElevations = {
    common: {
        boxShadow: `unset`
    },
    // Material white overlay transparency on background #121212 / rgb(18,18,18)
    dp00: `rgb(${afterOpacity(0)})`,
    dp01: `rgb(${afterOpacity(.05)})`,
    dp02: `rgb(${afterOpacity(.07)})`,
    dp03: `rgb(${afterOpacity(.08)})`,
    dp04: `rgb(${afterOpacity(.09)})`,
    dp06: `rgb(${afterOpacity(.11)})`,
    dp08: `rgb(${afterOpacity(.12)})`,
    dp12: `rgb(${afterOpacity(.14)})`,
    dp16: `rgb(${afterOpacity(.15)})`,
    dp24: `rgb(${afterOpacity(.16)})`,
};

/*
    No dark theme elevation support in Material UI till v5.
 */

const darkTheme = createTheme({
    typography: commonStyling.typography,
    palette: {
        type: `dark`,
        primary: {
            main: `#ef5350`,
            light: `#ff867c`,
            dark: `#b61827`,
            contrastText: `#fff`
        },
        secondary: {
            main: `#318c90`,
            light: `#50a5a9`,
            dark: `#0c6468`,
            contrastText: `#fff`
        },
        error: {
            main: `#CF6679`
        },
        background: {
            default: `#121212`,
            paper: `#121212`,
            dp00: darkElevations.dp00,
            dp01: darkElevations.dp01,
            dp02: darkElevations.dp02,
            dp03: darkElevations.dp03,
            dp04: darkElevations.dp04,
            dp06: darkElevations.dp06,
            dp08: darkElevations.dp08,
            dp12: darkElevations.dp12,
            dp16: darkElevations.dp16,
            dp24: darkElevations.dp24,
        }
    },
    overrides: {
        ...commonStyling.overrides,
        MuiPaper: {
            elevation0: {
                backgroundColor: darkElevations.dp00,
                ...darkElevations.common
            },
            elevation1: {
                backgroundColor: darkElevations.dp01,
                ...darkElevations.common
            },
            elevation2: {
                backgroundColor: darkElevations.dp02,
                ...darkElevations.common
            },
            elevation3: {
                backgroundColor: darkElevations.dp03,
                ...darkElevations.common
            },
            elevation4: {
                backgroundColor: darkElevations.dp04,
                ...darkElevations.common
            },
            elevation6: {
                backgroundColor: darkElevations.dp06,
                ...darkElevations.common
            },
            elevation8: {
                backgroundColor: darkElevations.dp08,
                ...darkElevations.common
            },
            elevation12: {
                backgroundColor: darkElevations.dp12,
                ...darkElevations.common
            },
            elevation16: {
                backgroundColor: darkElevations.dp16,
                ...darkElevations.common
            },
            elevation24: {
                backgroundColor: darkElevations.dp24,
                ...darkElevations.common
            },
        },
    },
});

export default darkTheme
