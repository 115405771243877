import {directusClient} from "../hooks/useDirectus";

const localStorageKey = `directus-sdk-js`;

function isLoggedIn() {
    return directusClient.loggedIn;
}

function getUser() {
    const token = getToken();
    if (!token) {
        return Promise.resolve(null)
    }
    return directusClient.get(`custom/users/0`, {}).then((data)=>{return data.data}).catch(error => {
        logout();
        return Promise.reject(error)
    })
}

function login({email, password}) {
    return directusClient
        .login({email: email, password: password})
        .then();
}

function register({firstname, surname, country, email, password}) {
    return directusClient.post(`custom/account`, {firstname: firstname, surname: surname, country:country, email: email, password: password}).then()
}

function logout() {
    directusClient.logout();
    return Promise.resolve();
}

function getToken() {
    if (typeof window === `undefined`) {
        return null;
    }
    return JSON.parse(window.localStorage.getItem(localStorageKey)).token;
}

export {login, register, logout, getUser, isLoggedIn}
