import { useState, useEffect, useDebugValue } from 'react';
import DirectusSDK from '@directus/sdk-js';

// main client instance
const directusSDKOptions = {
    url: process.env.GATSBY_API_URL,
    project: `_`, // Defaults to '_'
    persist: true, // Defaults to true
};
if (typeof window !== `undefined`) {
    directusSDKOptions.storage = window.localStorage;
}

// From Directus docs: Sorts by name ASC, followed by age DESC, followed by random sorting
// sort=name,-age,?
export const convertFilters = ({ limit = 15, page = 1, sort = `-modified_on`,  ...filters }) => {
    const apiFilters = {...filters.Api};

    // Convert to Directus v8 SDK syntax
    if (typeof filters.isRead === `boolean`) { apiFilters.is_read = { eq: filters.isRead ? 1 : 0 } }
    if (filters.fromId) { apiFilters.from_user = { eq: filters.fromId } }
    if (filters.toId) { apiFilters.to_user = { eq: filters.toId } }
    if (filters.status) { apiFilters.status = { eq: filters.status } }

    return { apiFilters, limit, page, sort };
}

export const directusClient = new DirectusSDK(directusSDKOptions);
// define the loading states
const ResponseState = {
    LOADING: `loading`,
    ERRORED: `errored`,
    SUCCESS: `success`
};
/*
OR?

export var ResponseState;
(function (ResponseState) {
    ResponseState["LOADING"] = "loading";
    ResponseState["ERRORED"] = "errored";
    ResponseState["SUCCESS"] = "success";
})(ResponseState || (ResponseState = {}));
 */

export function useDirectus(fn, opts) {
    // save error and response state internally
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    useEffect(() => {
        // will call the API with your provided fn
        const fetcher = async () => {
            try {
                const result = await fn(directusClient);
                // set the response if everything's alright
                setResponse(result);
            }
            catch (err) {
                // set the error if something went wrong
                setError(err);
            }
        };
        // execute!
        fetcher();
    }, []);
    const state = error
        ? ResponseState.ERRORED
        : response
            ? ResponseState.SUCCESS
            : ResponseState.LOADING;
    // debugging experience for devtools
    useDebugValue({ response, error, state }, ({ response, error, state }) => {
        return `${state}: ${response || error}`;
    });
    return [response, error, state];
}
