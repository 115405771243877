import {directusClient} from "../hooks/useDirectus";

const listTableNames = {
    MyCollection: `my_collection`,
    WantInTrade: `want_in_trade`,
    OfferForTrade: `offered_for_trade`,
    Buying: `buying`,
    Selling: `selling`
};
const listData = {
    MyCollection: {
        title: `My collection`,
        urlTitle: `my-collection`,
        table: `my_collection`
    },
    WantInTrade: {
        title: `Trades: Wanted`,
        urlTitle: `want-in-trade`,
        table: `want_in_trade`
    },
    OfferForTrade: {
        title: `Trades: Offered`,
        urlTitle: `offered-for-trade`,
        table: `offered_for_trade`
    },
    Buying: {
        title: `Sales: Wanted`,
        urlTitle: `buying`,
        table: `buying`
    },
    Selling: {
        title: `Sales: Offered`,
        urlTitle: `selling`,
        table: `selling`
    }
};
const listsArray = () => {
    let l = [];
    for (let listsKey in listTableNames) {
        l = [...l, listsKey];
    }
    return l;
};
const emptyLists = () => {
    let lists = {};
    for (let listsKey in listTableNames) {
        lists = {...lists, [listsKey]:[]};
    }
    return lists;
};

function create(list, listItemData) {
    return directusClient.createItem(listTableNames[list], listItemData);
}

function update(list, listItemId, updates) {
    return directusClient.updateItem(listTableNames[list], listItemId, updates);
}

async function getList(list, userId) {
    let {data: listItems} = await getItems(list, userId);
    return {
        title: listData[list].title,
        urlTitle: listData[list].urlTitle,
        items: listItems
    };
}

function getItems(list, userId) {
    // TODO: Check that it returns only owned items
    return directusClient.getItems(listTableNames[list], {filter:{created_by: {eq: userId}}}).catch(error => {return Promise.resolve({data:[]})});
}

function remove(list, listItemId) {
    return directusClient.deleteItem(listTableNames[list], listItemId);
}

export {listTableNames, listData, listsArray, emptyLists, create, remove, getList, getItems, update}
