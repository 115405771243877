import React, { createContext, useContext, useState, useEffect } from 'react'

const WindowDimensionsCtx = createContext(null)

const WindowDimensionsProvider = ({ children }) => {
    const [dimensions, setDimensions] = useState({
        width: 1000,
        height: 720,
    });
    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        };
        window.addEventListener(`resize`, handleResize);
        return () => { window.removeEventListener(`resize`, handleResize) }
    }, []);
    return (
        <WindowDimensionsCtx.Provider value={dimensions}>
            {children}
        </WindowDimensionsCtx.Provider>
    )
};

const useWindowDimensions = () => {
    const context = useContext(WindowDimensionsCtx);
    if (context === undefined) {
        throw new Error(
            `useWindowDimensions must be used within a WindowDimensionsProvider`,
        )
    }
    return context
};

export {WindowDimensionsProvider, useWindowDimensions}

/*
Example usage:

const ResponsiveTrade = ({ breakpoint, renderMobile, renderDesktop }) => {
    const { width } = useWindowDimensions();
    return (width > breakpoint ? renderDesktop : renderMobile)
};

<ResponsiveTrade breakpoint="400" renderDesktop={<ComponentWithProps prop1={value} />} renderMobile={ComponentWithNoProps} />} />

 */
