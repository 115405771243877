import { createSlice } from '@reduxjs/toolkit';
import {getCards} from "./cardsClient";
import {getCard} from "./cardsClient";

function startLoading(state) {
    state.isLoading = true;
}

function loadingFailed(state, action) {
    state.isLoading = false;
    state.error = action.payload;
}

// createSlice and createReducer wrap your function with produce from the Immer library.
// This means you can write code that "mutates" the state inside the reducer, and Immer will safely return a correct immutably updated result.
const cardsSlice = createSlice({
    name: `cards`,
    initialState: {
        cardsById: {},
        refreshInterval: 86400000, /* 24 hours in microseconds */
        isLoading: false,
        updated: ``,
        error: null,
    },
    reducers: {
        getCardsStart: startLoading,
        getCardsSuccess(state, {payload}) {
            const {cards, updated} = payload;
            if (cards.length > 0) {
                cards.forEach(card => {
                    state.cardsById[card.id] = card;
                    state.cardsById[card.id].updated = updated;
                });
                state.updated = updated;
            }
            state.isLoading = false;
            state.error = null;
        },
        getCardsFailure: loadingFailed,
        getCardStart: startLoading,
        getCardSuccess(state, {payload}) {
            const {card, updated} = payload;
            state.cardsById[card.id] = card;
            state.cardsById[card.id].updated = updated;
            state.isLoading = false;
            state.error = null;
        },
        getCardFailure: loadingFailed,
    }
});

export const {
    getCardsStart,
    getCardsSuccess,
    getCardsFailure,
    getCardStart,
    getCardSuccess,
    getCardFailure,
} = cardsSlice.actions;

export default cardsSlice.reducer;

// Thunks
export const fetchCards = (cardIds) => async (dispatch, getState) => {
    try {
        dispatch(getCardsStart());
        const uniqueSet = new Set(cardIds);
        const uniqueCardIds = [...uniqueSet];
        const {cards} = getState();
        const cardIdsInState = Object.keys(cards.cardsById);
        const toFetch = uniqueCardIds.filter(cardId => {
            // Don't fetch if card in state and doesn't need updating.
            return !cardIdsInState.includes(cardId.toString()) || (cards.cardsById[cardId].updated + cards.refreshInterval) < Date.now();
        });
        let fetchedCards = [];
        if (toFetch.length > 0) { fetchedCards = await getCards(toFetch); }
        dispatch(getCardsSuccess({cards: fetchedCards, updated: Date.now()}));
    } catch (err) {
        dispatch(getCardsFailure(err.toString()));
    }
};
export const fetchCard = (cardId) => async dispatch => {
    try {
        dispatch(getCardStart());
        const card = await getCard(cardId);
        dispatch(getCardSuccess({card, updated: Date.now()}));
    } catch (err) {
        dispatch(getCardFailure(err.toString()));
    }
};
